const {v4} = require("uuid");
const server = 'https://server.goalphacloud.com'
let isLoading = false
let loadingEle = null
let toastBody = null

function verifyEmail(email) {
	const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	if (email.value === "") {
		return false;
	}
	return reg.test(email);
}

const momentTz = require('moment-timezone');
import FingerprintJS from '@fingerprintjs/fingerprintjs';

// let fingerprint = null;
// (async () => {
// 	const fp = await FingerprintJS.load({screen_resolution: true});
//
// 	const result = await fp.get();
//
// 	fingerprint = result.visitorId;
//
// })();
async function getFingerprint () {
	const fp = await FingerprintJS.load({screen_resolution: true});

	const result = await fp.get();

	return result.visitorId;
}

async function getRequestAsync(url) {
	const myHeaders = new Headers();
	// myHeaders.append("User-Agent", "Apifox/1.0.0 (https://www.apifox.cn)");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Accept", "*/*");
	// myHeaders.append("Host", "124.220.215.2");
	myHeaders.append("Connection", "keep-alive");
	const sessionId = localStorage.getItem('sessionId')
	if (sessionId) {
		myHeaders.append("sessionId", sessionId);
	}

	// Add cache-control headers to prevent caching
	myHeaders.append("Cache-Control", "no-cache");
	myHeaders.append("Pragma", "no-cache");
	myHeaders.append("Expires", "0");

	var requestOptions = {
		method: 'GET',
		headers: myHeaders,
		redirect: 'follow'
	};

	// const self = this

	const response = await fetch(`${server}/${url}`, requestOptions)
	return response.json()
}

async function postRequestAsync(url, data) {
	var myHeaders = new Headers();
	// myHeaders.append("User-Agent", "Apifox/1.0.0 (https://www.apifox.cn)");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Accept", "*/*");
	// myHeaders.append("Host", "49.51.186.159");
	myHeaders.append("Connection", "keep-alive");
	const sessionId = localStorage.getItem('sessionId')
	if (sessionId) {
		myHeaders.append("sessionId", sessionId);
	}

	// Add cache-control headers to prevent caching
	myHeaders.append("Cache-Control", "no-cache");
	myHeaders.append("Pragma", "no-cache");
	myHeaders.append("Expires", "0");

	var raw = JSON.stringify(data);

	var requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: raw,
		redirect: 'follow'
	};

	// const self = this

	const response = await fetch(`${server}/${url}`, requestOptions)
	return response.json()
}

async function getAndroidDownloadLink(referCode) {
	const url = window.location.href;
	let code = null
	if (url.indexOf('fbtw') > -1) {
		code = 'fbtw'
	}
	if (url.indexOf('fbhk') > -1) {
		code = 'fbhk'
	}
	if (url.indexOf('fbzh') > -1) {
		code = 'fbzh'
	}
	const result = await postRequestAsync('version/getReferDownloadLink', { referCode: referCode, code, url })

	return result.msg.url
}

function showLoading () {
	if (isLoading === false) {
		const loadingContainer = document.createElement("div")
		loadingContainer.classList.add('loader-container')
		let loadingBody = document.createElement("div")
		loadingBody.classList.add('loader');
		loadingContainer.append(loadingBody)
		// toastBody.innerText = text
		document.body.append(loadingContainer)
		isLoading = true
		loadingEle = loadingContainer
	}

}

function startDownload (url) {
	let a = document.createElement('a');
	a.setAttribute('download', '');// download属性
	a.setAttribute('href', url);// href链接
	a.click();// 自执行点击事件
	a = null;
}

function hideLoading () {
	if (loadingEle) {
		document.body.removeChild(loadingEle)
		isLoading = false
		loadingEle = null
	}
}

function showToast(text) {
	if (toastBody) {
		document.body.removeChild(toastBody)
		toastBody = null
	}
	toastBody = document.createElement("div");
	toastBody.classList.add('sl-toast');
	toastBody.innerText = text
	document.body.append(toastBody)
	const timeoutID = window.setTimeout(() => {
		if (toastBody) {
			document.body.removeChild(toastBody)
			toastBody = null
		}
		window.clearTimeout(timeoutID)
	}, 3000);
}

function getClientId() {
	let clientId = localStorage.getItem('clientId')
	if (clientId) return clientId
	clientId = v4()
	localStorage.setItem('clientId', clientId)
	return clientId
}

function getUrlType() {
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	return urlParams.get('type')
}

function getUrlId() {
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	return urlParams.get('id')
}

async function getWindowsDownloadLink (referCode) {
	const url = window.location.href;
	const result = await postRequestAsync('version/getWindowsDownloadLink', {referCode: referCode, url})

	return result.msg.url
}

async function isSessionValid () {
	const sessionId = localStorage.getItem('sessionId')
	if (!sessionId) return {err: true}
	const result = await postRequestAsync('webLogin/verifySessionId', {sessionId: sessionId})
	if (!result) {
		localStorage.removeItem('sessionId')
		localStorage.removeItem('sessionExpireTimeStamp')
		return {err: true}
	}
	if (result.code === 200) {
		const expiredAtTimestamp = result.msg.expiredAtTimestamp
		localStorage.setItem('sessionExpireTimeStamp', expiredAtTimestamp)
		return {err: false, expiredAtTimestamp: expiredAtTimestamp, email: result.msg.email, planExpireTime: result.msg.planExpireTime}
	}

	localStorage.removeItem('sessionId')
	localStorage.removeItem('sessionExpireTimeStamp')
	return {err: true}
}

function formatTimestamp(timestamp) {

// 创建一个新的Date对象
	var date = new Date(timestamp * 1000);

// 使用Date对象的方法来获取日期和时间的各个部分
	var year = date.getFullYear(); // 获取年份
	var month = date.getMonth() + 1; // 获取月份，注意月份从0开始，所以需要加1
	var day = date.getDate(); // 获取日期
	var hours = date.getHours(); // 获取小时
	var minutes = date.getMinutes(); // 获取分钟
	var seconds = date.getSeconds(); // 获取秒数

	return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}

async function getPlanInfo () {
	const result = await getRequestAsync('web/getPlanInfo')

	return result
}

async function getAlipayLink (planType) {
	const sessionId = localStorage.getItem('sessionId')
	return await postRequestAsync('web/getAliPaymentLinkV2', {planType, sessionId})
}

async function getWechatPayLink (planType) {
	const sessionId = localStorage.getItem('sessionId')
	return await postRequestAsync('web/getWechatPaymentLinkV2', {planType, sessionId})
}

async function getCreditCardPaymentLink (planType) {
	const sessionId = localStorage.getItem('sessionId')
	return await postRequestAsync('web/getCreditCardPaymentLink', {planType, sessionId})
}

async function getUsdtTransactionId (planType) {
	const sessionId = localStorage.getItem('sessionId')
	return await postRequestAsync('web/createTransaction', {planType, sessionId})
}
async function getTransactionById (transactionId) {
	return await postRequestAsync('web/getTransactionById', {transactionId})
}

async function verifyTransaction (transactionId) {
	const sessionId = localStorage.getItem('sessionId')
	return await postRequestAsync('web/verifyTransaction', {transactionId, sessionId})
}

async function reportVisit (actionType = 'web_visit') {
	const url = window.location.href;
	const fingerprint = await getFingerprint()
	return await postRequestAsync('web/reportVisit', {url, fingerprint, actionType})
}

function getSessionId () {
	return  localStorage.getItem('sessionId')
}

function reportGoogle(url = undefined) {

	if (typeof window.gtag === 'function') {
		var callback = function () {
			if (typeof (url) != 'undefined') {
				window.location = url;
			}
		}
		window.gtag('event', 'conversion', {
			'send_to': 'AW-16533576363/GYxRCLz3v6oZEKu16cs9',
			'event_callback': callback
		});
	}
}

function reportFb (actionType) {
	if (typeof window.fbq === 'function') {
		window.fbq('trackCustom', actionType);
	}
}

export {
	getRequestAsync,
	postRequestAsync,
	verifyEmail,
	getAndroidDownloadLink,
	startDownload,
	showLoading,
	hideLoading,
	showToast,
	getClientId,
	getUrlType,
	getUrlId,
	getWindowsDownloadLink,
	isSessionValid,
	formatTimestamp,
	getPlanInfo,
	getAlipayLink,
	getWechatPayLink,
	getCreditCardPaymentLink,
	getUsdtTransactionId,
	getTransactionById,
	verifyTransaction,
	reportVisit,
	getSessionId,
	reportGoogle,
	reportFb
};
